<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <h3>Характеристики платежу</h3>
        <div>
          <span class="mr-2">ID:</span>
          <span class="font-weight-medium">{{ payment.id }}</span>
        </div>
        <div>
          <span class="mr-2">Створено:</span>
          <span class="font-weight-medium">
            {{ payment.created_at | getShortDate }}
            <sup>{{ payment.created_at | getShortTime }}</sup>
          </span>
        </div>
        <div>
          <span class="mr-2">Змінено:</span>
          <span class="font-weight-medium">
            {{ payment.updated_at | getShortDate }}
            <sup>{{ payment.updated_at | getShortTime }}</sup>
          </span>
        </div>
        <div>
          <span class="mr-2">Сума:</span>
          <span class="font-weight-medium">{{ payment.amount }}</span>
        </div>
        <div>
          <span class="mr-2">Тип:</span>
          <span class="font-weight-medium">{{ payment.payment_type_name }}</span>
        </div>
        <div>
          <span class="mr-2">ID торгівельної сесії:</span>
          <span class="font-weight-medium red--text">check for API</span>
        </div>
        <div>
          <span class="mr-2">Статус:</span>
          <span class="font-weight-medium">{{ payment.payment_status_name }}</span>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <h3>Відправник</h3>
        <div v-if="payment.user">
          <router-link
            :to="{ name: 'UserCard', params: { id: currentCompanyId, userId: payment.user.id } }"
            class="text-decoration-none font-weight-medium"
          >
            {{ payment.user.first_name }} {{ payment.user.last_name }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <h3>Отримувач</h3>
        <div v-if="payment.company">
          <span class="mr-2">Тип отримувача:</span>
          <span v-if="payment.device" class="font-weight-medium"> Автомат </span>
          <span v-else-if="payment.terminal" class="font-weight-medium"> Термінал </span>
          <span v-else-if="payment.service_point" class="font-weight-medium"> Точка </span>
          <span v-else-if="payment.company" class="font-weight-medium"> Компанія </span>
          <span v-else class="font-weight-medium">N/A</span>
        </div>
        <div v-if="payment.company">
          <span class="mr-2">Компанія:</span>
          <router-link
            :to="{ name: 'CompanyCard', params: { id: currentCompanyId } }"
            class="text-decoration-none font-weight-medium"
          >
            {{ payment.company.name }}
          </router-link>
        </div>
        <div v-if="payment.service_point">
          <span class="mr-2">Точка:</span>
          <router-link
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: payment.service_point.id } }"
            class="text-decoration-none font-weight-medium"
          >
            {{ payment.service_point.name }}
          </router-link>
        </div>
        <div v-if="payment.terminal">
          <span class="mr-2">Термінал:</span>
          <router-link
            :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: payment.terminal.id } }"
            :class="[{ disabled: payment.terminal.status === 3 }, 'text-decoration-none', 'font-weight-medium']"
          >
            <span :class="{ deleted: payment.terminal.status === 3 }">T{{ payment.terminal.id }}</span>
          </router-link>
        </div>
        <div v-if="payment.device">
          <span class="mr-2">Автомат:</span>
          <router-link
            :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: payment.device.id } }"
            :class="[{ disabled: payment.device.status === 3 }, 'text-decoration-none', 'font-weight-medium']"
          >
            <span :class="{ deleted: payment.device.status === 3 }"> {{ payment.device.name }}{{ payment.device.id }} </span>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12">
        <div v-if="isObject(payment.data) && !isObjectEmpty(payment.data)" class="mt-2" style="max-width: 600px">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Дані</v-expansion-panel-header>
              <v-expansion-panel-content>
                <pre style="font-size: 14px">{{ payment.data }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ConvertDate from '@/mixins/convertDate'
import { isObject } from '@/helpers/isObject'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'

export default {
  name: 'PaymentInfo',
  mixins: [ConvertDate],
  components: {},
  props: {
    payment: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentTab: {
      required: true,
      default: null,
    },
  },

  data: () => ({
    isObject: isObject,
    isObjectEmpty: isObjectEmpty,
  }),

  computed: {
    ...mapState('positions', ['positions']),
    currentCompanyId() {
      return this.$route.params.id
    },
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/vars';
.custom-field {
  display: block;
  max-width: 500px !important;
}
.deleted {
  color: $red;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
